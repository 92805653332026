<template>
  <v-form @submit.prevent>
    <v-row>
      <v-col cols="12" md="8">
        <fieldset>
          <label class="text-body-2 d-block mb-1">Role name</label>
          <v-text-field
            v-model="form.name" filled dense color="primary500"
            placeholder="role name" :rules="nameRules"
          />
        </fieldset>
      </v-col>

      <v-col cols="12" md="4">
        <fieldset>
          <label class="text-body-2 d-block mb-1">Inherits from</label>
          <v-select
            v-model="form.inherits"
            :items="[
              { text: 'None', value: null },
              ...Object.keys(roles).map(i => ({text: i, value: i}))
            ]"
            filled dense
            color="primary500"
          />
        </fieldset>
      </v-col>

      <v-col cols="12">
        <v-row dense class="mb-3">
          <v-col cols="12">
            <h5 class="text-body-2 font-weight-bold primary500--text text-uppercase">
              Permissions
            </h5>
          </v-col>
          <v-col
            v-for="(permission, name) in permissions" :key="name"
            cols="12" md="6" lg="4"
          >
            <v-card
              class="px-3 py-2 text-caption d-flex align-center"
              :class="{'faded-5': permission.inherited}"
              :disabled="permission.inherited"
              color="grey500" flat
              @click="onSelect(permission)"
            >
              <span class="text-caption text-truncate" v-text="name" />
              <v-spacer />
              <v-icon
                v-show="permission.active"
                class="text-body-2 green500--text"
              >
                mdi-check
              </v-icon>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="text-right">
        <v-btn
          light class="font-weight-bold"
          color="green--gradient"
          :loading="loading"
          @click="onSubmit"
        >
          <v-icon small class="mr-1">
            mdi-check-bold
          </v-icon>
          {{ isNew ? 'Create' : 'Update' }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { PERMISSIONS } from '@/utils/constants/permissions'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    roles: {
      type: Object,
      default: () => {},
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: null,
    }
  },
  computed: {
    permissions() {
      const perms = {}
      const { fullPermissions, inheritedRolePerms, $hasPermission } = this

      for (const key of Object.keys(PERMISSIONS)) {
        const flag = PERMISSIONS[key]

        perms[key] = {
          flag,
          inherited: inheritedRolePerms.includes(key),
          active: $hasPermission(fullPermissions, key),
        }
      }

      return perms
    },
    inheritedRole() {
      return this.roles?.[this.form.inherits] ?? null
    },
    inheritedRolePerms() {
      return this.inheritedRole?.rolePerms ?? []
    },
    fullPermissions() {
      const rolePerms = this.inheritedRole?.rolePermissions ?? 0

      // eslint-disable-next-line no-bitwise
      return this.form.permissions | rolePerms
    },
    nameRules() {
      const rules = [
        v => /^[a-z0-9-_]{2,32}$/.test(v) || 'Alphanumeric & dashes, no spaces or caps, 2-32 chars',
      ]

      if (!this.isEditing) {
        rules.push(v => !Object.values(this.roles).map(i => i.name).includes(v) || 'Already exists')
      }

      return rules
    },
  },
  watch: {
    value: {
      handler(val) {
        if (JSON.stringify(val) === JSON.stringify(this.form)) return
        this.form = { ...val }
      },
      immediate: true,
      deep: true,
    },
    form: {
      handler(val) {
        this.$emit('input', { ...val })
      },
      deep: true,
    },
    'form.inherits': function fn(val) {
      const roleFlags = this.inheritedRole?.rolePermissions ?? 0

      // remove inherited perms
      if (roleFlags) {
        const merged = this.form.permissions | roleFlags
        this.form.permissions = merged - roleFlags
      }
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.form)
    },
    onSelect(permission) {
      if (permission.inherited) return

      if (permission.active) {
        this.form.permissions -= permission.flag
      } else {
        this.form.permissions += permission.flag
      }
    },
  },
}
</script>
