<template>
  <v-container class="is-md py-12">
    <v-row align="end" class="mb-3">
      <v-col cols="12" md="8">
        <h3 class="text-h6 font-weight-bold">
          Manage user roles
        </h3>
        <p class="text-caption mb-0">
          Roles give or take permissions away from users.
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-right">
        <v-btn
          light color="primary--gradient" small :loading="loading.list"
          @click="fetchApiData"
        >
          <v-icon class="text-body-1 mr-1">
            mdi-sync
          </v-icon>
          Refresh
        </v-btn>
      </v-col>
    </v-row>

    <v-alert v-if="deletingRole" tile color="red">
      <div class="d-flex align-center">
        <v-icon large class="mr-4">
          mdi-alert
        </v-icon>
        <div>
          <h4 class="font-weight-medium">
            Confirm <b>deletion</b> of role <b>{{ deletingRole.name }}</b>.
          </h4>
          <p class="mb-0 text-body-2">
            This is irreversible and will affect users and other roles attached to it.
          </p>
        </div>
        <v-spacer />
        <v-btn
          small
          color="grey600" class="px-1 mr-2 unwidth"
          :ripple="false" @click="onDelete(deletingRole)"
        >
          <v-icon color="green500">
            mdi-check
          </v-icon>
        </v-btn>
        <v-btn
          small
          color="grey500" class="px-1 unwidth"
          :ripple="false" @click="deleting = null"
        >
          <v-icon color="red">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
    </v-alert>

    <v-data-table
      class="data-table mb-8"
      item-key="_id"
      :headers="headers"
      :items="roles"
      :footer-props="{ itemsPerPageOptions: [5, 10, 25] }"
      :expanded="expanded"
      :loading="loading.list"
      @pagination="onPagination"
    >
      <template #item.permissions="{ item }">
        <span class="primary500--text">
          {{ item.fullPerms.length }}
          perms
        </span>
      </template>

      <template #item.inherits="{ value }">
        <em class="text--secondary" v-text="value" />
      </template>

      <template #item.createdBy="{ item }">
        <div class="d-flex align-center">
          <UserAvatar :user="item.creator" size="24" class="mr-2" />
          <Username :user="item.creator" />
        </div>
      </template>

      <template #item.actions="{ item }">
        <v-icon small class="primary500--text link" @click="onExpandItem(item)">
          mdi-pencil
        </v-icon>

        <v-icon
          v-if="!item.permanent"
          small class="primary500--text link ml-2"
          @click="deleting = item._id"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- transaction info -->
      <template #expanded-item="{ headers: head, item }">
        <td :colspan="head.length" class="grey800 py-8 data-detail">
          <RoleForm
            :value="item"
            :roles="rolesObj"
            :loading="loading.create"
            @submit="onSetRole"
          />
        </td>
      </template>
    </v-data-table>

    <v-card class="pa-4" color="grey700">
      <h4 class="text-body-1 primary500--text mb-6 font-weight-bold">
        Create new role
      </h4>

      <RoleForm
        v-model="newRoleForm"
        :loading="loading.create"
        :roles="rolesObj"
        is-new
        @submit="onSetRole"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPermissionsList, getRolePermissions } from '@/utils/constants/permissions'

import RoleForm from './components/RoleForm'

const newRoleFormDefault = () => ({
  name: '',
  inherits: null,
  permissions: 0,
})

export default {
  metaInfo: {
    title: 'User roles',
  },
  components: {
    RoleForm,
  },
  data() {
    return {
      loading: {
        list: false,
        create: false,
      },
      deleting: null,
      expanded: [],
      headers: [
        { text: 'Name', value: 'name', align: 'start' },
        { text: 'Permissions', value: 'permissions' },
        { text: 'Inherits from', value: 'inherits' },
        { text: 'Last updated by', value: 'createdBy' },
        {
          text: null, value: 'actions', sortable: false, align: 'end',
        },
      ],
      entries: [],
      newRoleForm: newRoleFormDefault(),
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
    roles() {
      const { entries } = this

      if (!entries) return []

      const roles = entries.reduce((acc, v) => {
        acc[v.name] = {
          permissions: v.permissions,
          inherits: v.inherits,
        }
        return acc
      }, {})

      return entries.map(role => {
        const perms = getPermissionsList(role.permissions)
        const rolePermissions = getRolePermissions(role.name, roles)
        const rolePerms = getPermissionsList(rolePermissions)
        const fullPermissions = role.permissions | rolePermissions
        const fullPerms = getPermissionsList(fullPermissions)

        return {
          ...role,
          perms,
          rolePermissions,
          rolePerms,
          fullPermissions,
          fullPerms,
        }
      }).sort((a, b) => b.fullPerms.length - a.fullPerms.length)
    },
    rolesObj() {
      return this.roles.reduce((acc, v) => {
        acc[v.name] = v
        return acc
      }, {})
    },
    deletingRole() {
      const { deleting } = this
      if (!deleting) return null

      return this.roles.find(i => i._id === deleting)
    },
  },
  created() {
    this.fetchApiData()
  },
  methods: {
    onExpandItem(item) {
      const [expandedItem] = this.expanded

      if (item._id === expandedItem?._id) {
        this.expanded = []
      } else {
        this.expanded = [item]
      }
    },
    onPagination() {
      this.expanded = []
    },
    async fetchApiData() {
      this.loading.list = true

      try {
        const entries = await this.$socket.request('admin.roles.list')
        this.entries = entries
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading.list = false
      }
    },
    async onSetRole(form) {
      this.loading.create = true

      try {
        const flair = await this.$socket.request('admin.roles.set', form)

        this.newRoleForm = newRoleFormDefault()
        this.$toast.success(`User role "${flair.name}" updated successfully.`)

        this.fetchApiData()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.create = false
      }
    },
    async onDelete(role) {
      this.loading.create = true

      try {
        await this.$socket.request('admin.roles.delete', role.name)

        this.$toast.success(`User role "${role.name}" deleted successfully.`)
        this.deleting = null
        this.fetchApiData()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.create = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
